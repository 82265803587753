
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  background-color: #000000;
}

body{
  background-color: #000000;
}

.Title {
  color: #FF9100;
  font-family: 'Montserrat', sans-serif;
  font-size: 6vw;
  font-weight:800;
  letter-spacing: -3px;
  margin:0;
  text-align: center;
  padding:0;
  white-space: normal;
  width: 100%;
}


.TitleMobile {
  color: #FF9100;
  font-family: 'Montserrat', sans-serif;
  font-size: 10vw;
  font-weight:800;
  letter-spacing: -3px;
  margin:0;
  text-align: center;
  padding:0;
}


.SubTitle {
  color: white;
  font-family: 'DM Sans', sans-serif;
  font-size: 3vw;
  margin:0;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 5px;

  padding: 0;
}

.SubSubTitle {
  color: white;
  font-family: 'DM Sans', sans-serif;
  font-size: 2vw;
  font-weight:100;
  text-align: 'center';
  letter-spacing: 1px;
  margin-bottom: 5px;
  inline-size: 90vw;
  overflow-wrap: break-word;
}

.ContractAddressHeading {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 17px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.ContractAddress {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 13px;
  margin: 0px !important;
}


.media{
  width: "100px";
}


.buttonClass {
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
